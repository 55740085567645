import { type Chain, createClient, http, Transport } from "viem";
import { arbitrum, blast, mainnet } from "viem/chains";
import { Config, createConfig } from "wagmi";
import { injected, metaMask, walletConnect } from "wagmi/connectors";

const internalRpcById: Record<number, string> = {
  [blast.id]:
    "https://lively-wiser-shadow.blast-mainnet.quiknode.pro/b6508b73ed6528a3a5f0d549de2aa7efa6e2b0e4",
  [arbitrum.id]:
    "https://dawn-fabled-choice.arbitrum-mainnet.quiknode.pro/fabc06ac1ea3dc8bb35eb3754464747e9b85a15d",
  [mainnet.id]:
    "https://delicate-aged-dream.quiknode.pro/ae9b00306ca0056a1b6513567806a81ce6b5639a/",
};

const crtConfig = (chain: Chain) => {
  return createConfig({
    chains: [chain],
    client({ chain }) {
      const internalRpc = internalRpcById[chain.id];
      return createClient({
        chain,
        transport: internalRpc ? http(internalRpc) : http(),
      });
    },
    connectors: [
      metaMask(),
      walletConnect({
        projectId: process.env.NEXT_PUBLIC_WC_PROJECT_ID!,
        showQrModal: false,
      }),
      injected({ shimDisconnect: true }),
    ],
  });
};

const configByChain: Record<
  number,
  Config<readonly [Chain], Record<number, Transport>>
> = {
  [blast.id]: crtConfig(blast),
  [arbitrum.id]: crtConfig(arbitrum),
  [mainnet.id]: crtConfig(mainnet),
};

export const getConfig = (chainId: number) => {
  return configByChain[chainId]!;
};
