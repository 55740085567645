"use client";
import {
  getSystemTheme,
  isThemeVariant,
  useThemeConfigStore,
} from "@marginly/ui/stores/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";
import type { ThemeController } from "@web3modal/core";
import { authConnector } from "@web3modal/wagmi";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { config } from "process";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import type { Chain } from "viem/chains";
import { useAccount, WagmiProvider } from "wagmi";

import type { onWalletConnect, onWalletDisconnect } from "@/actions/wallet";
import logo from "@/components/navigation/images/logo.svg";
import logoWhite from "@/components/navigation/images/logo-dark.svg";
import { useContracts } from "@/contracts/react/hooks";
import { useMarginlyApi } from "@/hooks/api";
import { useMakeSendTracking } from "@/hooks/tracking";
import { PoolConfig } from "@/types/core";
import { generateUUID } from "@/util/fallback";

import { Web3Context } from "./context";
import type { ContextValues } from "./types";
import { getConfig } from "./wagmi-config/wagmi-config";

if (typeof window !== "undefined") {
  // required fallback for walletconnect initialize on old devices
  if (
    typeof crypto !== "undefined" &&
    typeof crypto.randomUUID !== "function"
  ) {
    crypto.randomUUID = generateUUID;
  }
}

const Contracts: React.FC<{
  children: (contracts: ContextValues) => React.ReactNode;
  pools: PoolConfig[];
  onWalletConnect?: typeof onWalletConnect;
  onWalletDisconnect?: typeof onWalletDisconnect;
  disableFeatures?: string[];
  hideDeleverage?: boolean;
}> = ({
  children,
  disableFeatures,
  pools,
  onWalletConnect,
  onWalletDisconnect,
}) => {
  const account = useAccount();
  const chainId = pools[0]?.chainId;
  const { baseurl, data: _chainSearch } = useMarginlyApi({
    endpoint: "chainSearch",
    args: [chainId],
  });
  const sendTracking = useMakeSendTracking();

  const internalChainId = _chainSearch?.chainInfo?.chainId;

  useEffect(() => {
    if (account.address) {
      onWalletConnect?.(account.address);
      sendTracking(baseurl, account.address);
    } else {
      onWalletDisconnect?.();
    }
  }, [
    account.address,
    onWalletConnect,
    onWalletDisconnect,
    baseurl,
    sendTracking,
  ]);

  const contracts = useMemo(() => {
    return pools.filter((v) => {
      for (const feature of disableFeatures ?? []) {
        if ((v as unknown as Record<typeof feature, boolean>)[feature]) {
          return false;
        }
      }

      return true;
    });
  }, [pools, disableFeatures]);

  const poolContracts = useContracts(contracts ?? []);
  return <>{children?.({ ...poolContracts, internalChainId })}</>;
};

type ThemeVars = (typeof ThemeController)["state"]["themeVariables"];

interface Web3ProviderProps {
  chains: Chain[];
  projectId: string;
  children?: React.ReactNode;
  pools: PoolConfig[];
  disableFeatures?: string[];
  onWalletConnect?: typeof onWalletConnect;
  onWalletDisconnect?: typeof onWalletDisconnect;
}

export function Web3Provider({
  chains,
  projectId,
  children,
  pools,
  disableFeatures,
  onWalletConnect,
  onWalletDisconnect,
}: Web3ProviderProps) {
  const w3mRef = useRef<ReturnType<typeof createWeb3Modal> | null>(null);
  const themeConfig = useThemeConfigStore();
  const themeMode = isThemeVariant(themeConfig)
    ? themeConfig
    : getSystemTheme();

  const chainId = chains[0]?.id;
  const W3M_THEME: ThemeVars = useMemo(
    () => ({
      "--w3m-accent-color": "var(--fill-secondary)",
      "--w3m-logo-image-url": themeMode === "dark" ? logoWhite.src : logo.src,
      "--w3m-background-color": "var(--background-primary)",
      "--w3m-color-fg-1": "var(--text-primary)",
      "--w3m-z-index": 1502,
    }),
    [themeMode],
  );

  const wagmiConfig = getConfig(chainId as number);

  if (!w3mRef.current) {
    w3mRef.current = createWeb3Modal({
      wagmiConfig: wagmiConfig,
      projectId,
      allowUnsupportedChain: true,
    });
    w3mRef.current.setThemeVariables(W3M_THEME);
  }

  useEffect(() => {
    w3mRef.current?.setThemeMode(themeMode);
  }, [themeMode]);

  useEffect(() => {
    wagmiConfig.setState((x) => ({
      ...x,
      chainId: chainId as number,
    }));
  }, []);

  const queryClient = new QueryClient();

  return (
    <>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <Contracts
            disableFeatures={disableFeatures}
            pools={pools}
            onWalletConnect={onWalletConnect}
            onWalletDisconnect={onWalletDisconnect}
          >
            {(contracts) => {
              return (
                <Web3Context.Provider value={contracts}>
                  {children}
                </Web3Context.Provider>
              );
            }}
          </Contracts>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
}

export const useWeb3Context = () => useContext(Web3Context);
