import { useMemo } from "react";

/** @description used to resolve typing issues; sometimes ;) having troubles with nested generics */
export function useApply<F extends (...args: any) => any>(
  transform: F | undefined,
  ...args: Parameters<NonNullable<F>>
): ReturnType<NonNullable<F>> | undefined {
  return useMemo(
    () =>
      !args
        ? transform?.()
        : Array.isArray(args)
        ? //@ts-ignore
          transform?.(...args)
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transform, ...args],
  );
}
