import PoolV15 from "@/contracts/abi/marginly-pool-v15";
import { AbiNameFilter } from "@/types/abi";
import { FilterEntry, UnwrapReadonlyArray } from "@/types/util";

type DefaultCalldataAbi = FilterEntry<
  UnwrapReadonlyArray<typeof PoolV15>,
  AbiNameFilter<"defaultSwapCallData">
>;

const DEFAULT_CALLDATA_ABI = PoolV15.find(
  (entry) => "name" in entry && entry.name === "defaultSwapCallData",
)! as DefaultCalldataAbi;

export const getDefaultCalldataSelector = (address?: `0x${string}`) =>
  address
    ? ({
        abi: [DEFAULT_CALLDATA_ABI],
        address,
        functionName: "defaultSwapCallData",
      } as const)
    : undefined;
