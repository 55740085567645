import { NULL_ADDRESS } from "@/constants/common";

import MarginlyPool from "../../abi/marginly-pool";

/**
 *
 * @param address
 * @returns apply convertBasePrice to useContractRead result
 */
export const getBasePriceSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "getLiquidationPrice", // the value suits more
  } as const);

export const baseTokenSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "baseToken",
  } as const);

export const quoteTokenSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "quoteToken",
  } as const);

/**
 *
 * @param address
 * @returns apply convertFP96 to useContractRead result
 */
export const baseCollateralCoeffSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "baseCollateralCoeff",
  } as const);

/**
 *
 * @param address
 * @returns apply convertFP96 to useContractRead result
 */
export const baseDebtCoeffSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "baseDebtCoeff",
  } as const);

/**
 *
 * @param address
 * @returns apply convertFP96 to useContractRead result
 */
export const baseDelevCoeffSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "baseDelevCoeff",
  } as const);

/**
 *
 * @param address
 * @returns apply convertFP96 to useContractRead result
 */
export const quoteCollateralCoeffSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "quoteCollateralCoeff",
  } as const);

/**
 *
 * @param address
 * @returns apply convertFP96 to useContractRead result
 */
export const quoteDebtCoeffSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "quoteDebtCoeff",
  } as const);

/**
 *
 * @param address
 * @returns apply convertFP96 to useContractRead result
 */
export const quoteDelevCoeffSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "quoteDelevCoeff",
  } as const);

/**
 *
 * @param address
 * @returns apply convertSystemLeverage to useContractRead result
 */
export const systemLeverageSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "systemLeverage",
  } as const);

/**
 *
 * @param address
 * @param who
 * @returns apply convertPosition to useContractRead result
 */
export const positionsSelector = (
  address?: `0x${string}`,
  who?: `0x${string}`,
) =>
  who && address
    ? ({
        abi: MarginlyPool,
        address,
        functionName: "positions",
        args: [who],
      } as const)
    : ({
        enabled: false,
        abi: MarginlyPool,
        address,
        functionName: "positions",
        args: [NULL_ADDRESS],
      } as const);

/**
 *
 * @param address
 * @returns apply convertWorkingMode to useContractRead result
 */
export const workingModeSelector = (address?: `0x${string}`) =>
  ({
    address,
    functionName: "mode",
  } as const);

/**
 *
 * @param address
 * @returns apply convertFP96 to useContractRead result
 */
export const emergencyWithdrawCoeffSelector = (address?: `0x${string}`) =>
  ({
    abi: MarginlyPool,
    address,
    functionName: "emergencyWithdrawCoeff",
  } as const);

/** @deprecated using for swap page */
export const getUniswapFee = (address?: `0x${string}`) =>
  ({
    abi: [
      {
        inputs: [],
        name: "uniswapFee",
        outputs: [
          {
            internalType: "uint24",
            name: "",
            type: "uint24",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    address,
    functionName: "uniswapFee",
  } as const);
