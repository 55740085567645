import type { Abi } from "viem";

import { AbiNameFilter } from "@/types/abi";
import type { FilterEntry, Flatten, HasKey, UnwrapReadonlyArray } from "@/types/util";

export function getAbiEntry<
  ABI extends Abi,
  NAME extends HasKey<UnwrapReadonlyArray<ABI>, "name">["name"],
>(abi: ABI, name: NAME) {
  return abi.find(
    (entry) => "name" in entry && entry.name === name,
  )! as Flatten<FilterEntry<UnwrapReadonlyArray<ABI>, AbiNameFilter<NAME>>>;
}
