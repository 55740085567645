"use client";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useChainId,
  useChains,
  useConfig,
  useReadContract,
  useReadContracts,
} from "wagmi";

import { W3_CACHE } from "@/constants/common";
import { useDexPartner } from "@/contexts/partner";
import { useRx } from "@/contexts/rx";
import { useWeb3Context } from "@/contexts/web3";
import { useMarginlyApi } from "@/hooks/api";
import { usePushSubject } from "@/hooks/rx";
// fixme fetchToken seems to be needed to move out of hooks
import { fetchToken, type TokenDetails } from "@/hooks/token";
import { useApply } from "@/hooks/util";
import { CallSwapType, PositionSwapType } from "@/types/api";
import { PoolConfig, PoolConfigWithTokens } from "@/types/core";
import type { Partner } from "@/util/server/env";

import {
  baseTokenSelector,
  quoteTokenSelector,
} from "../selectors/marginly-pool";
import { getDefaultCalldataSelector } from "../selectors/marginly-pool/v15";

export interface PoolInfo {
  pool: PoolConfigWithTokens;
  baseToken?: TokenDetails;
  quoteToken?: TokenDetails;
}

type ContractReadConfig =
  | ReturnType<typeof baseTokenSelector>
  | ReturnType<typeof quoteTokenSelector>;

export const useContracts = (poolConfig: PoolConfig[]) => {
  const { error$ } = useRx();
  const chainId = useChainId();
  const { open } = useWeb3Modal();
  const config = useConfig();
  const [tokenData, setTokenData] = useState<
    Record<NonNullable<TokenDetails["address"]>, TokenDetails>
  >({});
  const currentPools = useMemo(
    () => poolConfig.filter((pool) => chainId === pool?.chainId),
    [chainId, poolConfig],
  );

  const contracts = useMemo(
    () =>
      currentPools.reduce<ContractReadConfig[]>((prev, { address }) => {
        return [
          ...prev,
          baseTokenSelector(address),
          quoteTokenSelector(address),
        ];
      }, []),
    [currentPools],
  );

  const {
    data,
    isFetching: pending,
    isSuccess: ready,
    error,
  } = useReadContracts({
    contracts,
    query: {
      staleTime: W3_CACHE.ETERNAL.staleTime,
    },
  });

  usePushSubject(error$, error);
  const { pools, addressToIndex } = useMemo(() => {
    const addressToIndex: Record<`0x${string}`, number> = {};

    const pools: PoolConfigWithTokens[] = currentPools.map(
      ({ address, ...pool }, i) => {
        addressToIndex[address] = i;
        const j = i * 2;

        const [baseToken, quoteToken] =
          data?.slice(j, j + 2).map((x) => x.result) ?? [];

        return {
          address,
          baseToken,
          quoteToken,
          ...pool,
        } as PoolConfigWithTokens;
      },
    );

    return { addressToIndex, pools };
  }, [data, currentPools]);

  const tokens = useMemo(() => {
    const set = new Set<`0x${string}`>();

    for (const { baseToken, quoteToken } of pools ?? []) {
      if (baseToken) {
        set.add(baseToken);
      }

      if (quoteToken) {
        set.add(quoteToken);
      }
    }

    return Array.from(set);
  }, [pools]);

  useEffect(() => {
    Promise.all(tokens.map((address) => fetchToken(address, config))).then(
      (res) => {
        const nextTokenData = res.reduce<
          Record<`0x${string}`, Required<TokenDetails>>
        >(
          (acc, el) => ({ ...acc, [el.address!]: el as Required<typeof el> }),
          {},
        );

        setTokenData(nextTokenData);
      },
    );
  }, [tokens, chainId, config]);

  const getPoolByAddress = useCallback(
    (address?: `0x${string}`): PoolInfo | undefined => {
      if (!address || !Number.isFinite(addressToIndex[address])) {
        return undefined;
      }

      const pool = pools[
        addressToIndex[address] as number
      ] as PoolConfigWithTokens;

      const [baseToken, quoteToken] = [pool?.baseToken, pool?.quoteToken].map(
        (address) => (address ? tokenData[address] : undefined),
      );

      return { pool, baseToken, quoteToken };
    },
    [addressToIndex, pools, tokenData],
  );

  return {
    pools,
    pending,
    ready,
    getPoolByAddress,
    tokens,
    tokenData,
    open,
  };
};

const PARTNER_ALIAS: Partial<Record<Partner | "_default", string>> = {
  _default: "Default",
  Camelot: "Algebra",
};

export function useSwapCallData({
  poolAddress,
  callType,
  exactAmount,
  // only for callType === CallSwapType.ClosePosition
  positionType = "Undefined",
}: {
  poolAddress?: `0x${string}`;
  exactAmount?: string;
} & (
  | {
      callType?: Exclude<CallSwapType, "ClosePosition">;
      positionType?: "Undefined";
    }
  | {
      callType: "ClosePosition";
      positionType: PositionSwapType;
    }
)): string | undefined {
  const { internalChainId, getPoolByAddress } = useWeb3Context();
  const { partner: _partner } = useDexPartner();
  const pool = useApply(getPoolByAddress, poolAddress);

  const defaultCallData = useReadContract({
    query: {
      enabled: pool?.pool.flip,
    },
    ...getDefaultCalldataSelector(poolAddress),
  });

  const enabled = Boolean(
    callType &&
      internalChainId &&
      poolAddress &&
      Number(exactAmount) > 0 &&
      !pool?.pool.isFarm,
  );

  const partner = (PARTNER_ALIAS[_partner ?? "_default"] ?? _partner)!;

  const result = useMarginlyApi({
    enabled,
    endpoint: "router.swapCallData",
    args: [
      internalChainId,
      poolAddress,
      callType,
      exactAmount,
      positionType,
      partner,
    ],
  });

  return (
    result?.data?.swapCalldata ??
    (defaultCallData?.data as number | undefined)?.toString(10) ??
    "0"
  );
}
