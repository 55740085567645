import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { erc20Abi, formatUnits } from "viem";
import { useBalance, useBlockNumber, useReadContracts } from "wagmi";

export const useTokenBalance = ({
  address,
  token,
  query,
  chainId,
}: {
  address: `0x${string}` | undefined;
  token: `0x${string}` | undefined;
  query?: { staleTime: number; enabled?: boolean };
  chainId?: number;
}) => {
  const { enabled = true } = query || {};
  const {
    data: nativeData,
    queryKey: nativeQueryKey,
    error: nativeError,
  } = useBalance({
    address,
    query: { enabled: Boolean(!token && address && enabled) },
  });
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const queryClient = useQueryClient();
  const { data, queryKey, error } = useReadContracts({
    query: { ...query, enabled: Boolean(address && token && enabled) },
    allowFailure: false,
    contracts: [
      {
        address: token,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [address!],
        chainId,
      },
      {
        address: token,
        abi: erc20Abi,
        functionName: "decimals",
        chainId,
      },
      {
        address: token,
        abi: erc20Abi,
        functionName: "symbol",
        chainId,
      },
    ],
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey });
    queryClient.invalidateQueries({ queryKey: nativeQueryKey });
  }, [blockNumber, queryClient]);

  const notNativeToken = data && {
    value: data[0],
    decimals: data[1],
    symbol: data[2],
    formatted: formatUnits(data[0], data[1]),
  };

  const finalData = token ? notNativeToken : nativeData;

  return {
    data: finalData,
    error: nativeError || error,
  };
};
