import { readContracts } from "@wagmi/core";
import { useMemo } from "react";
import { Config, useChainId, useReadContracts } from "wagmi";

import { CHAINID_MAP } from "@/constants/chains";
import erc20Abi from "@/contracts/abi/ERC20";
import { FilterEntry, UnwrapPromise, WithNullableEntry } from "@/types/util";
import { getAbiEntry } from "@/util/abi";
import { compareAddress } from "@/util/core";

import { useTokenBalance } from "./wagmi/use-token-balance";

const USDC_E_ARBITRUM = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
const USDC_NATIVE_ARBITRUM = "0xaf88d065e77c8cc2239327c5edb3a432268e5831";

interface TokenDetailsOverride {
  symbol?: string;
  name?: string;
}

// const balanceOfAbi = [getAbiEntry(erc20Abi, "balanceOf")] as const;
const decimalsAbi = [getAbiEntry(erc20Abi, "decimals")] as const;
const symbolAbi = [getAbiEntry(erc20Abi, "symbol")] as const;
const nameAbi = [getAbiEntry(erc20Abi, "name")] as const;

const getTokenContracts = (address: `0x${string}`) =>
  [
    {
      address,
      abi: decimalsAbi,
      functionName: "decimals",
    },
    {
      address,
      abi: symbolAbi,
      functionName: "symbol",
    },
    {
      address,
      abi: nameAbi,
      functionName: "name",
    },
  ] as const;

const fetchTokenRaw = (address: `0x${string}`, config: Config) => {
  return readContracts(config, {
    allowFailure: false,
    contracts: getTokenContracts(address),
  });
};

type RawTokenData = UnwrapPromise<ReturnType<typeof fetchTokenRaw>>;

export const normalizeToken = (
  address?: `0x${string}`,
  ...[decimals, symbol, name]: RawTokenData
) => {
  const tokenOverride: TokenDetailsOverride = {};

  if (compareAddress(address, USDC_E_ARBITRUM)) {
    tokenOverride.symbol = "USDC.e";
    tokenOverride.name = "Bridged USDC";
  } else if (compareAddress(address, USDC_NATIVE_ARBITRUM)) {
    tokenOverride.name = "Native USDC";
  }

  const normalizedToken = { address, decimals, symbol, name, ...tokenOverride };
  return normalizedToken;
};

export const useBalanceNormalize = (props: {
  address: `0x${string}` | undefined;
  token: `0x${string}` | undefined;
  query?: { staleTime: number; enabled?: boolean };
  chainId?: number;
}) => {
  const { token } = props;
  const tokenIn = useTokenBalance(props);
  if (!tokenIn.data) {
    return tokenIn;
  }

  const updatedData = {
    ...tokenIn.data,
    name: tokenIn.data.symbol,
  };

  const { name, symbol } = normalizeToken(
    token,
    tokenIn.data.decimals,
    tokenIn.data.symbol,
    tokenIn.data.symbol,
  );

  updatedData.name = name;
  updatedData.symbol = symbol;

  return {
    ...tokenIn,
    data: updatedData,
  };
};

export type TokenDetails = ReturnType<typeof normalizeToken>;

export const fetchToken = (address: `0x${string}`, config: Config) =>
  fetchTokenRaw(address, config).then((rest) =>
    normalizeToken(address, ...rest),
  );

export const useToken = ({
  address,
  enabled,
}: {
  address?: `0x${string}`;
  enabled?: boolean;
}) => {
  const chainId = useChainId();
  const chain = CHAINID_MAP[chainId];
  const contracts = useMemo(
    () => address && getTokenContracts(address),
    [address],
  );

  const result = useReadContracts({
    query: {
      enabled: contracts && enabled,
    },
    contracts: contracts!,
  });

  return useMemo<WithNullableEntry<TokenDetails, "address"> | undefined>(() => {
    if (!enabled) {
      return;
    }

    if (!address) {
      return chain?.nativeCurrency;
    }

    if (result.data?.every((v) => v.status === "success")) {
      const rest = result.data.map(
        (v) => (v as FilterEntry<typeof v, { status: "success" }>).result,
      );

      return normalizeToken(address, ...(rest as RawTokenData));
    }
  }, [address, enabled, result.data, chain?.nativeCurrency]);
};
